import { useEffect } from 'react';

import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import { api } from 'api';

import { filterLfgByGame } from 'utils/filterGame';
import { getIsOnboardingCompletedKey } from 'utils/location';

import type { SupportedGameId } from 'api/types';

export enum Slide {
  ButtonsAuth = 'ButtonsAuth',
  GameBlocks = 'GameBlocks',
  StreamerSuggestions = 'StreamerSuggestions',
  GameSettings = 'GameSettings',
}

export enum OnboardingType {
  JoinGroup,
  Auth,
}

export type ListElement = {
  slide: Slide;
  next: Slide | null;
  prev: Slide | null;
};

export const DEFAULT_SLIDES = [
  Slide.ButtonsAuth,
  Slide.GameBlocks,
  Slide.StreamerSuggestions,
  Slide.GameSettings,
];

export const ONBOARDING_KEY = 'onboarding';
export type Onboarding = {
  lastPage?: string;
  lastSlide?: Slide;
  gameId?: SupportedGameId;
  onSuccess?: () => void;
  onSuccessAfterClose?: () => void;
};

// Mark onbnoarding as completed if user
// has at least one lfg with matching game
export function logOnboardingCompleted(
  gameId: api.Maybe<SupportedGameId>,
  profiles: api.Lfg[],
) {
  const isCompletedKey = getIsOnboardingCompletedKey(gameId);
  const isCompleted = localStorage.getItem(isCompletedKey);

  if (isCompleted) {
    return;
  }

  if (gameId) {
    const profile = filterLfgByGame(gameId, profiles);

    if (!profile || !profile.games?.length) {
      return;
    }

    localStorage.setItem(isCompletedKey, 'true');

    return;
  }

  if (profiles?.length) {
    localStorage.setItem(isCompletedKey, 'true');

    return;
  }
}

export function getIsOnboardingCompleted(
  gameId: api.Maybe<SupportedGameId>,
): boolean {
  const isCompletedKey = getIsOnboardingCompletedKey(gameId);
  const isCompleted = localStorage.getItem(isCompletedKey);

  return isCompleted === 'true';
}

export const generateSlideList = (
  me: api.Maybe<api.User>,
  slides: api.Maybe<Slide[]>,
  initialGame: api.Maybe<SupportedGameId>,
): ListElement[] => {
  const modalSlides = [...(slides || DEFAULT_SLIDES)];

  if (me) {
    // find and delete Auth slide if user is logged in
    const authSlideIndex = modalSlides.findIndex(
      slide => slide === Slide.ButtonsAuth,
    );
    if (authSlideIndex !== -1) {
      modalSlides.splice(authSlideIndex, 1);
    }
  }

  if (initialGame) {
    // find and delete GameBlocks slide if initialGame is set
    const gameBlockSlideIndex = modalSlides.findIndex(
      slide => slide === Slide.GameBlocks,
    );
    if (gameBlockSlideIndex !== -1) {
      modalSlides.splice(gameBlockSlideIndex, 1);
    }
  }

  const completeResult = modalSlides.map((slide, index) => {
    // пока нигде не используется, но возможно потом понадобится
    const prev = modalSlides[index - 1] || null;
    const next = modalSlides[index + 1] || null;

    return { next, prev, slide };
  });

  return completeResult;
};

export const checkForCanBeModalClosed = (
  myLfgsLenght: number,
  slide: Slide,
  canBeClosed: api.Maybe<boolean>,
) => {
  if (myLfgsLenght > 0) {
    if (slide === Slide.StreamerSuggestions && myLfgsLenght < 2) {
      return false;
    }

    return true;
  }
  if (canBeClosed) {
    return true;
  }
  if (slide === Slide.GameBlocks || slide === Slide.StreamerSuggestions) {
    return false;
  }

  return true;
};

export const useUpdateFungerprint = (
  isOnboardingOpen: boolean,
  me: api.Maybe<api.Me>,
  isMobile: boolean,
) => {
  const [updateUser] = api.useUpdateUserMutation();

  useEffect(() => {
    if (!isOnboardingOpen && !isMobile) {
      return;
    }

    if (!me?.user?.id) {
      return;
    }

    const shouldUpdateFingerprint = (): boolean => {
      if (me?.fingerprintUpdatedAt !== '') {
        const fingerprintUpdatedAt = new Date(me?.fingerprintUpdatedAt);
        // if last update fingerprintUpdatedAt was more then a week ago, update fingerprint
        const week = 604800000;
        if (Date.now() - week > fingerprintUpdatedAt.getTime()) {
          return true;
        }

        return false;
      }
      if (!me.fingerprintId) {
        return true;
      }

      return false;
    };

    if (shouldUpdateFingerprint()) {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load({
        apiKey: 'xe7mYvqYxD0GSeK3Xuww',
        region: 'ap',
      });

      // Get the visitor identifier when you need it.
      fpPromise
        .then(fp => fp.get())
        .then(async result => {
          try {
            await updateUser({
              variables: {
                data: { fingerprintId: result.visitorId },
              },
            });
          } catch (error) {}
        });
    }
  }, [isOnboardingOpen, me]);
};
