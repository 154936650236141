import React from 'react';

import cx from 'classnames';
import Link from 'next/link';

import { api } from 'api';

import css from './Avatar.module.css';

interface AvatarProps
  extends Omit<
    React.ImgHTMLAttributes<HTMLImageElement>,
    'src' | 'width' | 'height'
  > {
  url?: string;
  size?: number;
  online?: boolean;

  onlineContainerClassname?: string;
}

export const Avatar = React.memo<AvatarProps>(
  ({ url, onlineContainerClassname, online = false, className, ...props }) => {
    if (!url) {
      return <div className={cx(css.container, className)} />;
    }

    return (
      <div className={cx(css.container, className)}>
        <div
          style={{ backgroundImage: `url("${url}")` }}
          {...props}
          className={css.image}
          draggable={false}
        />

        <div
          className={cx(
            css.onlineContainer,
            { [css.offline]: !online },
            onlineContainerClassname,
          )}
        />
      </div>
    );
  },
);

export interface AvatarUserProps extends Omit<AvatarProps, 'url'> {
  user: api.User;
  link?: boolean;
  hideOnline?: boolean;
  onlineContainerClassname?: string;
  avatarLinkClassName?: string;
  onLinkClick?: (event: React.MouseEvent) => void;
}

export const AvatarUser = React.memo<AvatarUserProps>(
  ({ user, onLinkClick, link = true, hideOnline = false, ...props }) => {
    if (!link) {
      return (
        <div
          className={cx(css.linkContainer, props.avatarLinkClassName)}
          onClick={onLinkClick}
        >
          <Avatar
            online={!hideOnline && user.online}
            url={user.avatarUrl}
            {...props}
          />
        </div>
      );
    }

    return (
      <Link
        className={cx(css.linkContainer, props.avatarLinkClassName)}
        href={`/${user.username}`}
        onClick={onLinkClick}
      >
        <Avatar online={!hideOnline && user.online} url={user.avatarUrl} {...props} />
      </Link>
    );
  },
);

interface AvatarListProps {
  users: api.User[];
}
export const AvatarList = React.memo<AvatarListProps>(({ users }) => {
  return (
    <div className={css.listContainer}>
      {users
        .reverse()
        .slice(0, 3)
        .map(user => (
          <AvatarUser key={user.id} className={css.avatarContainer} user={user} />
        ))}
    </div>
  );
});
