import React, { useState, useRef } from 'react';

import cx from 'classnames';

import { Portal } from 'components/Portal';
import { useOnClickOutside } from 'utils/main';

import { List } from '../List';

import css from './MoreInfo.module.css';

interface MoreInfoProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onSelectItem?: (item: T) => void;
  className?: string;
  listClassName?: string;
  dotsClassName?: string;
}

export function MoreInfo<T>({
  items,
  renderItem,
  onSelectItem = () => undefined,
  className,
  listClassName,
  dotsClassName,
}: MoreInfoProps<T>): React.ReactElement {
  const moreInfoRef = useRef<HTMLDivElement>(null);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  useOnClickOutside(moreInfoRef, () => setIsListOpen(false));

  return (
    <div ref={moreInfoRef} className={cx(css.moreInfo, className)}>
      <ThreeDots
        className={cx({ [css.activeInfo]: isListOpen }, dotsClassName)}
        onClick={() => setIsListOpen(!isListOpen)}
      />

      <Portal root={moreInfoRef.current}>
        <List
          className={cx(css.list, { [css.listOpen]: isListOpen }, listClassName)}
          items={items}
          renderItem={renderItem}
          onSelectItem={onSelectItem}
        />
      </Portal>
    </div>
  );
}

interface ThreeDotsProps {
  onClick?: () => void;
  className?: string;
  dotWidth?: number;
}
export const ThreeDots = React.memo<ThreeDotsProps>(
  ({ onClick, dotWidth = 2, className }) => (
    <div className={cx(css.dots, className)} onClick={onClick}>
      <div className={css.dot} style={{ width: dotWidth, height: dotWidth }} />
      <div className={css.dot} style={{ width: dotWidth, height: dotWidth }} />
      <div className={css.dot} style={{ width: dotWidth, height: dotWidth }} />
    </div>
  ),
);
