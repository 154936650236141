import { useCallback, useMemo } from 'react';

import { useRouter } from 'next/router';

import { useApp } from 'components/ContextApp';
import { Onboarding, ONBOARDING_KEY } from 'containers/ModalAuthOnboarding/helpers';
import { dynamic } from 'utils/dynamic';
import { links } from 'utils/links';

import { useModal } from './helpers';

import type { ModalProps } from 'containers/ModalAuthOnboarding/Container';

const ModalAuthOnboarding = dynamic(() =>
  import('containers/ModalAuthOnboarding').then(mod => mod.ModalAuthOnboarding),
);

interface OnboardingModalResponse {
  open: (props: ModalProps) => void;
  prefetch: () => void;
}

// Since onboarding modal on mobile is a separate page, use this hook
// to open onboarding
export function useOnboardingModal(): OnboardingModalResponse {
  const app = useApp();
  const router = useRouter();
  const modal = useModal();

  const handleOpenDesktop = useCallback((props: ModalProps) => {
    modal.open(ModalAuthOnboarding.Component, props);
  }, []);
  const handlePrefetchDesktop = useCallback(() => {
    ModalAuthOnboarding.prefetch();
  }, []);

  const handleOpenMobile = useCallback(
    (props: ModalProps) => {
      const onboardingInfo: Onboarding & ModalProps = {
        lastPage: router.asPath,
        ...(props || {}),
      };

      localStorage.setItem(
        ONBOARDING_KEY,
        JSON.stringify({
          ...onboardingInfo,
          onSuccess: onboardingInfo?.onSuccess?.toString(),
          onSuccessAfterClose: onboardingInfo?.onSuccessAfterClose?.toString(),
        }),
      );

      router.push(links.modals.onboarding());
    },
    [router.asPath],
  );

  const handlePrefetchMobile = useCallback(() => {}, []);

  const result = useMemo<OnboardingModalResponse>(() => {
    return {
      open: app.isMobile ? handleOpenMobile : handleOpenDesktop,
      prefetch: app.isMobile ? handlePrefetchMobile : handlePrefetchDesktop,
    };
  }, [app.isMobile]);

  return result;
}
