import React from 'react';

import cx from 'classnames';

import { Icon } from 'components/Icons';
import { Text } from 'components/Text';

import { useText } from 'text';
import { useScrollToTop } from 'utils/main';

import css from './ButtonScrollToTop.module.css';

interface ButtonScrollToTopProps {
  className?: string;
}

export const ButtonScrollToTop: React.FC<ButtonScrollToTopProps> = ({
  className,
}) => {
  const text = useText(state => state.controls);

  const { isTop, scrollToTop } = useScrollToTop();

  return (
    <button
      className={cx(css.scrollButton, className)}
      style={{
        visibility: isTop ? 'hidden' : 'visible',
        opacity: isTop ? 0 : 1,
      }}
      onClick={scrollToTop}
    >
      <Icon.ArrowUp className={css.scrollButtonIcon} />
      <Text.R1 semibold className={css.scrollButtonText}>
        {text.actions.up}
      </Text.R1>
    </button>
  );
};
