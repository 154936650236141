import { useCallback, useMemo } from 'react';

import { useRouter } from 'next/router';

import { useApp } from 'components/ContextApp';
import { EVENT_MODAL_KEY } from 'containers/ModalDesktopEvent/helpers';
import { dynamic } from 'utils/dynamic';

import { links } from 'utils/links';

import { useModal } from './helpers';

import type { ModalProps } from 'containers/ModalDesktopEvent/Container';

const ModalDesktopEvent = dynamic(() =>
  import('containers/ModalDesktopEvent').then(mod => mod.ModalEvent),
);

interface EventModalResponse {
  open: (props: ModalProps) => void;
  prefetch: () => void;
}

// Since onboarding modal on mobile is a separate page, use this hook
// to open onboarding
export function useEventModal(): EventModalResponse {
  const app = useApp();
  const modal = useModal();
  const router = useRouter();

  const handleOpenDesktop = useCallback((props: ModalProps) => {
    modal.open(ModalDesktopEvent.Component, props);
  }, []);
  const handlePrefetchDesktop = useCallback(() => {
    ModalDesktopEvent.prefetch();
  }, []);

  const handleOpenMobile = useCallback(
    (props: ModalProps) => {
      const eventModalInfo = {
        lastPage: router.asPath,
        ...(props || {}),
      };

      localStorage.setItem(EVENT_MODAL_KEY, JSON.stringify(eventModalInfo));

      router.push(links.modals.eventSettings());
    },
    [router.asPath],
  );

  const handlePrefetchMobile = useCallback(() => {}, []);

  const result = useMemo<EventModalResponse>(() => {
    return {
      open: app.isMobile ? handleOpenMobile : handleOpenDesktop,
      prefetch: app.isMobile ? handlePrefetchMobile : handlePrefetchDesktop,
    };
  }, [app.isMobile]);

  return result;
}
