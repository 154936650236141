import { isPast, isToday, startOfDay } from 'date-fns';

import { OptionForm } from '../SelectForm';

export const sec = 1000;
export const min = sec * 60;
export const hour = min * 60;
export const day = hour * 24;

export const step = 1 * min;

// 600 000 -> `00:10`
export function getTimeStamp(ms: number): string {
  const sec = 1000;
  const min = sec * 60;
  const totalMins = Math.floor(ms / min);

  const hours = Math.floor(totalMins / 60).toString();
  const mins = (totalMins % 60).toString();

  return `${hours.padStart(2, '0')}:${mins.padStart(2, '0')}`;
}

export function createOptions(): OptionForm<number>[] {
  /*
   * Value = offset ms from start of the day,
   * i.e. Mon Aug 30 2021 00:00 = 1 630 270 800 867
   * to make it 12:00 you need to add `43200000` (1000 * 60 * 60 * 12)
   */
  const options: OptionForm<number>[] = [];

  for (let offset = 0; offset < day; offset += step) {
    options.push({ value: offset, label: getTimeStamp(offset) });
  }

  return options;
}

// If given date is today, slices all past timestamps
export function sliceOptions(
  date: Date,
  options: OptionForm<number>[],
  from?: Date,
  showNextDay?: boolean,
  hidePast = true,
): OptionForm<number>[] {
  const isDateToday = isToday(date);

  if (!isDateToday && isPast(date) && hidePast) {
    return [];
  }

  const now = new Date();
  const offset = getInDayOffset(from || now);
  const point = getNearestOffset(offset) / step;

  // slice nearest from start
  if (from) {
    return [...options.slice(point)];
  }

  if (!isDateToday) {
    return options;
  }

  if (!showNextDay) {
    return [...options.slice(point)];
  }

  return [...options.slice(point), ...options.slice(0, point)];
}

export function getInDayOffset(date: Date): number {
  return date.getTime() - startOfDay(date).getTime();
}
export function getNearestOffset(offset: number): number {
  return Math.ceil(offset / step) * step;
}
export function getNearestStart(date: Date): Date {
  const offset = getInDayOffset(date);
  const nearestOffset = getNearestOffset(offset);

  return new Date(startOfDay(date).getTime() + nearestOffset);
}
