import { useEffect, useRef } from 'react';

import { api, COOKIE_AUTH_TOKEN_KEY, getToken } from 'api';
import { getNearestStart } from 'components/DateTimePicker/helpers';
import { API_ENDPOINT } from 'const';
import { filterLfgByGame } from 'utils/filterGame';

import type { SupportedGameId } from 'api/types';

export const CUSTOM_BUTTON_INFO_KEY = 'custom-button-info';

export const PREVIOUS_EVENT_KEY = `previous-event`;
export const EVENT_MODAL_KEY = `event-modal`;

export type InputErrors = {
  [K in keyof api.EventInput]?: string;
};

export function storeEventSettings(event: api.EventInput) {
  const str = JSON.stringify(event);
  localStorage.setItem(PREVIOUS_EVENT_KEY, str);
}

export function getPreviousEventSettings(): api.EventInput | null {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const str = localStorage.getItem(PREVIOUS_EVENT_KEY);

  if (!str) {
    return null;
  }
  const event = JSON.parse(str) as api.EventInput;

  return event;
}

const GAME_DEFAULT_EVENT_MODE: Partial<Record<SupportedGameId, api.GameMode>> = {
  [api.GameId.LeagueOfLegends]: api.GameMode.LolClash,
  [api.GameId.WorldOfWarcraft]: api.GameMode.WowMythicPlus,
  [api.GameId.LostArkEn]: api.GameMode.LostArkLegionRaids,
  [api.GameId.PubgMobile]: api.GameMode.Custom,
  [api.GameId.CodMobile]: api.GameMode.Custom,
  [api.GameId.Standoff2]: api.GameMode.Custom,
  [api.GameId.GooseGooseDuck]: api.GameMode.Custom,
  [api.GameId.ShadowFight3]: api.GameMode.Custom,
  [api.GameId.ShadowFight4Arena]: api.GameMode.Custom,
  [api.GameId.DawnOfZombiesSurvival]: api.GameMode.Custom,
  [api.GameId.Crossfire]: api.GameMode.Custom,
  [api.GameId.NewStateMobile]: api.GameMode.Custom,
  [api.GameId.RiseOfEmpires]: api.GameMode.Custom,
  [api.GameId.ModernStrikeOnlinePvPfps]: api.GameMode.Custom,
  [api.GameId.RiseOfKingdomsLostCrusade]: api.GameMode.Custom,
  [api.GameId.IdleHeroes]: api.GameMode.Custom,
  [api.GameId.LastDayOnEarthSurvival]: api.GameMode.Custom,
  [api.GameId.MobileLegendsAdventure]: api.GameMode.Custom,
  [api.GameId.Stalker2HeartOfChornobyl]: api.GameMode.Custom,
  [api.GameId.Highrise]: api.GameMode.Custom,
  [api.GameId.AfkArena]: api.GameMode.Custom,
};

export function getDefaultInput(
  data: Partial<api.EventInput>,
  userData: { user: api.Maybe<api.User> },
): api.EventInput {
  const input: api.EventInput = {
    ...data,
    gameId: undefined as any,
    scheduledAt: getNearestStart(new Date()),
  };

  if (userData.user?.language && !input.language) {
    input.language = userData.user.language;
  }
  if (!input.type) {
    input.type = api.EventType.Event;
  }

  if (userData.user?.roles?.includes(api.UserRole.Streamer)) {
    if (typeof localStorage !== 'undefined') {
      const buttonInfoJson = localStorage.getItem(CUSTOM_BUTTON_INFO_KEY);
      const buttonInfo = buttonInfoJson ? JSON.parse(buttonInfoJson) : undefined;

      if (buttonInfo) {
        input.links = {
          websiteTitle: buttonInfo?.title,
          websiteUrl: buttonInfo?.url,
        };
      }
    }
  }

  return input;
}

export function getDefaultGameInput(
  gameId: api.Maybe<SupportedGameId>,
  data: Partial<api.EventInput>,
  userData: {
    profiles: api.Lfg[];
    user: api.Maybe<api.User>;
  },
): api.EventInput {
  if (!gameId) {
    return getDefaultInput(data, userData);
  }

  const input = { ...data } as api.EventInput;
  input.gameId = gameId;

  // Drop fields that may be associated with other games
  delete input.type;
  delete input.mode;
  delete input.region;
  delete input.rating;
  delete input.hasWaitlist;
  delete input.lostArk;
  delete input.hearthstone;
  delete input.wow;
  delete input.csgo;
  delete input.pubg;
  delete input.cod;
  delete input.warzone;
  delete input.lol;
  delete input.wowBurningCrusade;

  const profile = filterLfgByGame(gameId, userData.profiles);

  if (userData.user?.language && !input.language) {
    input.language = userData.user.language;
  }

  if (!input.type) {
    input.type = api.EventType.Event;
  }
  if (!input.mode) {
    const defaultMode = GAME_DEFAULT_EVENT_MODE[gameId];
    input.mode = defaultMode;
  }

  if (
    gameId !== api.GameId.PubgMobile &&
    gameId !== api.GameId.CodMobile &&
    gameId !== api.GameId.Standoff2 &&
    gameId !== api.GameId.GooseGooseDuck &&
    gameId !== api.GameId.ShadowFight3 &&
    gameId !== api.GameId.ShadowFight4Arena &&
    gameId !== api.GameId.DawnOfZombiesSurvival &&
    gameId !== api.GameId.Crossfire &&
    gameId !== api.GameId.NewStateMobile &&
    gameId !== api.GameId.RiseOfEmpires &&
    gameId !== api.GameId.ModernStrikeOnlinePvPfps &&
    gameId !== api.GameId.RiseOfKingdomsLostCrusade &&
    gameId !== api.GameId.IdleHeroes &&
    gameId !== api.GameId.LastDayOnEarthSurvival &&
    gameId !== api.GameId.MobileLegendsAdventure &&
    gameId !== api.GameId.Stalker2HeartOfChornobyl &&
    gameId !== api.GameId.Highrise &&
    gameId !== api.GameId.AfkArena
  ) {
    input.hasWaitlist = true;
  }
  // Auto-set region field for newer games
  if (profile?.region) {
    input.region = profile.region;
  }

  // Apply deep game-specific fields
  if (gameId === api.GameId.LeagueOfLegends) {
    input.lol = { region: api.LolRegion.Unset };

    if (profile?.lol?.region) {
      input.lol.region = profile.lol.region;
    }
  }
  if (gameId === api.GameId.Hearthstone) {
    input.hearthstone = { region: api.HearthstoneRegion.Unset };

    // if (profile.hearthstone?.region) {
    //   input.hearthstone.region = profile.region;
    // }
  }
  if (gameId === api.GameId.Csgo) {
    input.csgo = { region: api.CsgoRegion.Unset };

    // if (profile.csgo?.region) {
    //   input.csgo.region = profile.csgo.region;
    // }
  }
  if (gameId === api.GameId.WorldOfWarcraft) {
    input.wow = {};

    if (profile?.wow?.region) {
      input.wow.region = profile.wow.region;
    }
  }
  if (gameId === api.GameId.LostArkEn) {
    input.lostArk = { region: api.LostArkRegion.Unset };

    if (profile?.lostArk?.region?.length) {
      input.lostArk.region = profile.lostArk.region[0] as api.LostArkRegion;
    }
  }

  return input;
}

export function eventToInput(event: Partial<api.Event>): api.EventInput {
  return {
    ...(event.region && { region: event.region }),
    ...(event.rating && { rating: event.rating }),
    ...(event.externalUrl && { externalUrl: event.externalUrl }),
    ...(event.description && { description: event.description }),
    ...(event.gameId && { gameId: event.gameId }),
    ...(event.name && { name: event.name }),
    ...(event.mode && { mode: event.mode }),
    ...(event.type && { type: event.type }),
    ...(event.language && { language: event.language }),
    ...(event.scheduledAt && { scheduledAt: event.scheduledAt }),
    ...(event.scheduledTo && { scheduledTo: event.scheduledTo }),
    ...(event.giveawayGoal && { giveawayGoal: event.giveawayGoal }),
    ...(event.giveawayGoalMembersCount && {
      giveawayGoalMembersCount: event.giveawayGoalMembersCount,
    }),
    ...(event.links && {
      links: {
        ...(event.links.websiteUrl && { websiteUrl: event.links.websiteUrl }),
        ...(event.links.websiteTitle && { websiteTitle: event.links.websiteTitle }),
      },
    }),
    ...(event.lol && {
      lol: {
        ...(event.lol.rank && { rank: event.lol.rank }),
        ...(event.lol.region && { region: event.lol.region }),
      },
    }),
    ...(event.warzone && {
      warzone: { ...(event.warzone.region && { region: event.warzone.region }) },
    }),
    ...(event.pubg && {
      pubg: {
        ...(event.pubg.password && { password: event.pubg.password }),
        ...(event.pubg.server && { server: event.pubg.server }),
      },
    }),
    ...(event.cod && {
      cod: {
        ...(event.cod.password && { password: event.cod.password }),
        ...(event.cod.server && { server: event.cod.server }),
      },
    }),
    ...(event.standoff2 && {
      standoff2: {
        ...(event.standoff2.inviteLink && { inviteLink: event.standoff2.inviteLink }),
      },
    }),
    ...(event.wow && {
      wow: {
        ...(event.wow.region && { region: event.wow.region }),
        ...(event.wow.faction && { faction: event.wow.faction }),
        ...(event.wow.arenaRating && { arenaRating: event.wow.arenaRating }),
        ...(event.wow.dungeon && { dungeon: event.wow.dungeon }),
        ...(event.wow.ilvl && { ilvl: event.wow.ilvl }),
        ...(event.wow.mythicPlusKey && { mythicPlusKey: event.wow.mythicPlusKey }),
        ...(event.wow.mythicPlusRating && {
          mythicPlusRating: event.wow.mythicPlusRating,
        }),
        ...(event.wow.raid && { raid: event.wow.raid }),
        ...(event.wow.raidDifficulty && { raidDifficulty: event.wow.raidDifficulty }),
      },
    }),
    ...(event.csgo && {
      csgo: {
        ...(event.csgo.rank && { rank: event.csgo.rank }),
        ...(event.csgo.region && { region: event.csgo.region }),
      },
    }),
    ...(event.hearthstone && {
      hearthstone: {
        ...(event.hearthstone.rating && { rating: event.hearthstone.rating }),
        ...(event.hearthstone.region && { region: event.hearthstone.region }),
      },
    }),
    ...(event.hearthstone && {
      hearthstone: {
        ...(event.hearthstone.rating && { rating: event.hearthstone.rating }),
        ...(event.hearthstone.region && { region: event.hearthstone.region }),
      },
    }),
    ...(event.lostArk && {
      lostArk: {
        region: event.lostArk.region,
        ...(event.lostArk.abyssDungeonsAbyss && {
          abyssDungeonsAbyss: event.lostArk.abyssDungeonsAbyss,
        }),
        ...(event.lostArk.abyssDungeonsDungeon && {
          abyssDungeonsDungeon: event.lostArk.abyssDungeonsDungeon,
        }),
        ...(event.lostArk.abyssDungeonsDungeonGearScore && {
          abyssDungeonsDungeonGearScore: event.lostArk.abyssDungeonsDungeonGearScore,
        }),
      },
    }),
    ...(event.wowBurningCrusade && {
      wowBurningCrusade: {
        region: event.wowBurningCrusade.region,
        faction: event.wowBurningCrusade.faction,
        arenaRating: event.wowBurningCrusade.arenaRating,
        dungeon: event.wowBurningCrusade.dungeon,
        ilvl: event.wowBurningCrusade.ilvl,
      },
    }),
  };
}
export async function uploadPictureBlob(blobUrl: string): Promise<string> {
  const req = await fetch(blobUrl);
  const blob = await req.blob();
  const image = new File([blob], 'image', { type: blob.type });

  const formData = new FormData();
  formData.append('photo', image);

  const token = getToken();

  if (!token) {
    throw new Error("Can't upload image without token");
  }

  const res = await fetch(`https://${API_ENDPOINT}/upload/event/photo`, {
    method: 'POST',
    body: formData,
    headers: { [COOKIE_AUTH_TOKEN_KEY]: token },
  });

  const data = await res.json();

  return data.id;
}

interface OpenEffectArgs {
  didOpen?: () => unknown | Promise<unknown>;
  didClose?: () => unknown | Promise<unknown>;
}

export function useOpenEffect(
  isOpen: boolean,
  handlers: OpenEffectArgs,
  deps: any[] = [],
) {
  const prevState = useRef<boolean | null>(null);

  return useEffect(() => {
    if (!isOpen) {
      if (prevState.current) {
        prevState.current = isOpen;
        // console.log('setting prev', isOpen);

        if (handlers.didClose) {
          handlers.didClose();
        }
      }

      return;
    }

    if (prevState.current !== true && handlers.didOpen) {
      prevState.current = isOpen;
      // console.log('setting prev 2', isOpen);
      handlers.didOpen();
    }
  }, [isOpen, ...deps]);
}

export function deleteEmptyPrefixes(input: api.EventInput, prev: api.Event) {
  // if (!input.region && prev?.region) {
  //   input.region = '';
  // }
  // if (!input.lol?.region && prev?.lol?.rank) {
  //   if (!input.lol) {
  //     input.lol = {};
  //   }
  //   input.lol.region = '' as api.LolRegion;
  // }
  // if (!input.csgo?.region && prev?.csgo?.region) {
  //   if (!input.csgo) {
  //     input.csgo = {};
  //   }
  //   input.csgo.region = '' as api.CsgoRegion;
  // }
  // if (!input.hearthstone?.region && prev?.hearthstone?.region) {
  //   if (!input.hearthstone) {
  //     input.hearthstone = {};
  //   }
  //   input.hearthstone.region = '' as api.HearthstoneRegion;
  // }
  // if (!input.lostArk?.region && prev?.lostArk?.region) {
  //   if (!input.lostArk) {
  //     input.lostArk = {};
  //   }
  //   input.lostArk.region = '' as api.LostArkRegion;
  // }
  // if (!input.wow?.region && prev?.wow?.region) {
  //   if (!input.wow) {
  //     input.wow = {};
  //   }
  //   input.wow.region = '' as api.WowRegion;
  // }
  if (!input.scheduledTo && prev?.scheduledTo) {
    input.scheduledTo = '';
  }
  if (input.scheduledAt === prev?.scheduledAt) {
    delete input.scheduledAt;
  }
  if (input.scheduledTo === prev?.scheduledTo) {
    delete input.scheduledTo;
  }
}
